import type {
  HeadlineResponse,
  LatestForumPostsResponse,
  NilHundredResponseNil100FilterResponseRelatedPagedData,
  PlayerItemResponsePagedData,
  ScoreboardResponseRelatedScheduleResponseRelatedPagedData,
  SimpleHeadlineResponseSimpleSiteResponseRecordRelatedPagedData,
} from '@on3/api';
import { ISiteContext, useSite } from '@on3/ui-lib/contexts/SiteProvider';
import { MobileMPU } from 'components/Ads/MobileMPU';
import { MPU } from 'components/Ads/MPU';
import { Forums } from 'components/Forums/Forums';
import { CondensedList } from 'components/Headlines/ArticleCondensed/CondensedList';
import { CoverList } from 'components/Headlines/ArticleCover/CoverList';
import { FeedList } from 'components/Headlines/ArticleFeed/FeedList';
import { PremiumArticleList } from 'components/Headlines/PremiumArticle/PremiumArticleList';
import { HomeLayout } from 'components/Layouts/HomeLayout';
import { SignUpFooter } from 'components/Newsletter/SignUpFooter';
import { Nil100Widget } from 'components/Nil/Nil100Widget';
import { ScheduleModule } from 'components/Schedules/Sidebar/Module';
import { Connect } from 'components/Social/Connect';
import { LatestCommits } from 'components/Teams/LatestCommits/LatestCommits';
import { useMemo } from 'react';
import { on3Data } from 'store/mocks/Connect.mock';
import { useAds } from 'utils/hooks/useAds';

import styles from './ChannelHome.module.scss';

export interface IChannelHomeProps {
  featuredArticles: HeadlineResponse[] | null;
  teamSports: HeadlineResponse[] | null;
  teamRecruiting: HeadlineResponse[] | null;
  channelSports?: HeadlineResponse[] | null;
  channelRecruiting?: HeadlineResponse[] | null;
  messageBoard?: LatestForumPostsResponse | null;
  latestCommits: PlayerItemResponsePagedData | null;
  teamNil100: NilHundredResponseNil100FilterResponseRelatedPagedData | null;
  premiumArticles: SimpleHeadlineResponseSimpleSiteResponseRecordRelatedPagedData | null;
  siteData?: ISiteContext;
  teamSchedule: ScoreboardResponseRelatedScheduleResponseRelatedPagedData | null;
}

export const ChannelHome = ({
  featuredArticles,
  channelSports,
  channelRecruiting,
  teamSports,
  teamRecruiting,
  messageBoard,
  latestCommits,
  teamNil100,
  premiumArticles,
  teamSchedule,
}: IChannelHomeProps) => {
  const { currentSite } = useSite();

  useAds({ pageType: 'home' });

  const sportViewAll = useMemo(() => {
    return [
      {
        url: `${currentSite?.url}/category/football/news/`,
        text: 'View All Football',
        title: 'Football News',
      },
      {
        url: `${currentSite?.url}/category/basketball/news/`,
        text: 'View All Basketball',
        title: 'Basketball News',
      },
    ];
  }, [currentSite?.url]);

  const recruitingViewAll = useMemo(() => {
    return [
      {
        url: `${currentSite?.url}/category/football-recruiting/news/`,
        text: 'View All Football Recruiting',
        title: 'Football Recruiting News',
      },
      {
        url: `${currentSite?.url}/category/basketball-recruiting/news/`,
        text: 'View All Basketball Recruiting',
        title: 'Basketball Recruiting News',
      },
    ];
  }, [currentSite?.url]);
  const coverArticles = featuredArticles?.slice(0, 3);

  return (
    <>
      {!!coverArticles?.length && (
        <CoverList
          backgroundColor="#ffffff"
          categoryColor="#6f747b"
          list={coverArticles}
        />
      )}
      <HomeLayout>
        <section className={styles.feed}>
          <div className={styles.inner}>
            {!channelSports?.length || (
              <FeedList
                list={channelSports.slice(0, 5)}
                section={`${currentSite?.organization?.name} Sports`}
                viewAllLinks={sportViewAll}
              />
            )}
            <MobileMPU id={'mobile_mpu_top'} incontent />
            {!channelRecruiting?.length || (
              <FeedList
                list={channelRecruiting.slice(0, 5)}
                section={`${currentSite?.organization?.name} Recruiting`}
                viewAllLinks={recruitingViewAll}
              />
            )}
          </div>
        </section>
        <aside className={styles.sidebarright}>
          <div className={styles.inner}>
            <MPU id={'mpu_top'} />
            {!!premiumArticles?.list?.length && (
              <PremiumArticleList
                list={premiumArticles?.list}
                relatedModel={premiumArticles?.relatedModel}
              />
            )}
            {!!latestCommits?.list?.length && (
              <LatestCommits list={latestCommits?.list} />
            )}
            {!messageBoard?.posts?.length || <Forums list={messageBoard} />}
            <MPU className={styles.sticky} id={'mpu_middle'} />
            <MobileMPU id={'mobile_mpu_middle'} incontent />
          </div>
        </aside>
        <aside className={styles.sidebarleft}>
          <div className={styles.inner}>
            {!!teamSports?.length && !!teamRecruiting?.length && (
              <CondensedList
                list={teamSports?.slice(0, 5)}
                secondaryList={teamRecruiting?.slice(0, 5)}
              />
            )}
            {!!teamNil100?.list?.length && (
              <Nil100Widget list={teamNil100?.list?.slice(0, 5)} />
            )}
            {!!teamSchedule?.list?.length && (
              <ScheduleModule
                list={teamSchedule?.list}
                relatedModel={teamSchedule?.relatedModel}
              />
            )}
            <Connect list={on3Data} title="On3 Connect" />
          </div>
        </aside>
      </HomeLayout>
      <SignUpFooter />
    </>
  );
};
