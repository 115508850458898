import {
  type NewsletterSubscribeRequest,
  v1SitesSubscribeToNewsletterCreate,
} from '@on3/api';
import { Form } from '@on3/ui-lib/components/Form';
import { Input } from '@on3/ui-lib/components/Input/Input';
import { useSite } from '@on3/ui-lib/contexts/SiteProvider';
import { externalApi } from '@on3/ui-lib/src/utils/api';
import { yup } from '@on3/ui-lib/utils/yup';
import { Link } from 'components/Link';
import { useState } from 'react';

import styles, { on3Primary } from './SignUpFooter.module.scss';

const schema = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
});

const defaultState: NewsletterSubscribeRequest = {
  email: '',
  requireConfirmation: true,
};

interface ISignUpProps {
  titleText?: string;
  bodyText?: string;
  buttonText?: string;
}
export const SignUpFooter = ({
  titleText,
  bodyText,
  buttonText,
}: ISignUpProps) => {
  const { currentSite } = useSite();
  const primaryColor =
    currentSite?.linkColor || currentSite?.primaryColor || on3Primary;

  const title = titleText || `${currentSite?.name} Newsletter`;
  const btnText = buttonText || "I'm game";
  const details =
    bodyText ||
    'The No. 1 College Sports newsletter delivering breaking news and the most popular reads straight to your inbox.';
  const [message, setMessage] = useState({ error: false, text: '' });
  const hasNewsletter = currentSite?.newsletterListId;
  const subscribeKey = hasNewsletter ? currentSite?.key : 44;
  const [state, setState] = useState<NewsletterSubscribeRequest>(defaultState);

  const handleSubmit = async (values: NewsletterSubscribeRequest) => {
    try {
      await v1SitesSubscribeToNewsletterCreate(
        externalApi,
        subscribeKey,
        values,
      );
      setMessage({ error: false, text: 'Success!' });
      setState(defaultState);
    } catch (error) {
      setMessage({ error: true, text: 'Something went wrong.' });
      console.error('Error subscribing to newsletter:', error);
    } finally {
      setTimeout(() => {
        setMessage({ error: false, text: '' });
      }, 5000);
    }
  };

  return (
    <div className={styles.block}>
      <section>
        <header>
          <h2>{title}</h2>
          <p>{details}</p>
        </header>
        <div className={styles.container}>
          {message.text ? (
            <p
              className={styles.message}
              data-type={message.error ? 'error' : 'success'}
            >
              {message.text}
            </p>
          ) : (
            <Form onSubmit={handleSubmit} schema={schema} values={state}>
              {({ errors, processing }) => (
                <div>
                  <Input
                    error={errors.email}
                    label="Email"
                    name="email"
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, email: e.target.value }))
                    }
                    placeholder="Email..."
                    srLabel
                    value={state.email}
                  />

                  <button
                    disabled={processing}
                    style={{ backgroundColor: primaryColor }}
                    type="submit"
                  >
                    {btnText}
                  </button>
                </div>
              )}
            </Form>
          )}
        </div>
        <p className={styles.terms}>
          {`By clicking "${btnText}", I agree to On3's`}{' '}
          <Link href="/page/privacy-policy/">Privacy Notice</Link>,
          {' '}<Link href="/page/terms-of-service/">Terms</Link>,{' '}
          {`and use of my personal information described therein.`}
        </p>
      </section>
    </div>
  );
};
