import { format, fromUnixTime, parse } from 'date-fns';

import { FilterOption } from '../components/Filter/Filter';
import { slugify } from './slugify';

export function formatCurrency(
  num: number | string,
  fixed?: number | undefined | null,
) {
  if (num?.toString() === '0') {
    return '$0';
  }

  if (!num) {
    return '-';
  }

  const isNegative = +num < 0;
  const absoluteNumber = Math.abs(+num);

  let formattedValue: string;

  if (absoluteNumber < 20) {
    formattedValue = `$${absoluteNumber.toFixed(fixed || 0)}`;
  } else if (absoluteNumber < 1000) {
    formattedValue = `$${parseInt(
      absoluteNumber.toFixed(fixed || 0),
    ).toLocaleString()}`;
  } else {
    formattedValue = `$${formatMillions(absoluteNumber)}`;
  }

  return isNegative ? `-${formattedValue}` : formattedValue;
}

export function formatMillions(
  num: number | string,
  fixed?: number | undefined | null,
) {
  if (num === null || num === undefined) {
    return '-';
  }

  const absoluteNumber = Math.abs(+num);

  if (absoluteNumber > 20000000000) {
    return `${parseFloat(`${+num / 1000000000}`).toFixed(0)}B`;
  } else if (absoluteNumber >= 999500000) {
    return `${parseFloat(`${+num / 1000000000}`)
      .toFixed(1)
      .replace(/\.0{1,}$/, '')}B`;
  } else if (absoluteNumber > 20000000) {
    return `${parseFloat(`${+num / 1000000}`).toFixed(0)}M`;
  } else if (absoluteNumber >= 999500) {
    return `${parseFloat(`${+num / 1000000}`)
      .toFixed(1)
      .replace(/\.0{1,}$/, '')}M`;
  } else if (absoluteNumber > 20000) {
    return `${parseFloat(`${+num / 1000}`).toFixed(0)}K`;
  } else if (absoluteNumber >= 1000) {
    return `${parseFloat(`${+num / 1000}`)
      .toFixed(1)
      .replace(/\.0{1,}$/, '')}K`;
  } else if (absoluteNumber < 20) {
    return `${parseFloat(`${num}`)
      .toFixed(2)
      .replace(/\.0{2,}$/, '')}`;
  }

  return parseFloat(`${num}`).toFixed(fixed || 0);
}

export const currencyFormatterCommas = (
  value: number,
  minimumFractionDigits?: number,
  maximumFractionDigits?: number,
) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: minimumFractionDigits || 0,
    maximumFractionDigits: maximumFractionDigits || 0,
  }).format(value);

export const addCommasToNumber = (number: number): string =>
  number.toLocaleString();

export const formatMoney = (value?: number) => {
  if (!value) {
    return `$0`;
  }

  const formatedValue = value ? formatMillions(Math.abs(value)) : 0;

  return value < 0 ? `-$${formatedValue}` : `$${formatedValue}`;
};

//**Takes a string and places a space before capital letters */
export const formatEnumToLabel = (value: string): string => {
  if (!value) {
    return '';
  }

  return value.replace(/(?!^)([A-Z])/g, ' $1');
};

export const convertHeightToInches = (height: string) => {
  if (!height) return null;

  const [feet, inches] = height.split('-');
  const totalInches = parseInt(feet) * 12 + parseInt(inches);

  return totalInches;
};

export const formatHeight = (height: number) => {
  if (!height) return;

  return `${Math.floor(height / 12)}-${(height % 12).toFixed(0)}`;
};

/**
 * Formats a phone number to (XXX) XXX-XXXX
 */
export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';

  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return phoneNumber;
};

/**
 * Formats a birthdate to MM/DD/YYYY
 */
export const formatBirthdate = (
  birthdate: string | number | null | undefined,
) => {
  if (!birthdate) return '';

  if (typeof birthdate === 'string') {
    const cleaned = ('' + birthdate).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{4})$/);

    if (match) {
      return match[1] + '/' + match[2] + '/' + match[3];
    }

    return birthdate;
  }

  if (typeof birthdate === 'number') {
    const date = fromUnixTime(birthdate);

    return format(date, 'MM/dd/yyyy');
  }
};

/**
 * Formats a date to unix timestamp
 */
export const formatDateToUnix = (
  dateString: string,
  dateFormat: string = 'MM/DD/YYYY',
) => {
  const date = parse(dateString, dateFormat, new Date());

  return Math.floor(date.getTime() / 1000);
};

/**
 * Formats a social handle to '@handle'
 */
export const formatSocialHandle = (handle: string | null) => {
  if (!handle) return '';

  return handle.startsWith('@') ? handle : `@${handle}`;
};

/**
 * Formats a number to a string with commas
 */
export const formatNumber = (value: number) => {
  if (!value) return '';

  return value.toLocaleString();
};

interface FormatFilterOptionsParams<T> {
  data: T[];
  labelKey: keyof T;
  valueKey: keyof T;
  defaultOption?: string;
}

export const formatFilterOptions = <T>({
  data,
  labelKey,
  valueKey,
  defaultOption,
  addAll = false,
}: Partial<FormatFilterOptionsParams<T>> & {
  addAll?: boolean;
}): FilterOption[] => {
  if (!data || data.length === 0) {
    return [];
  }

  const options = data.reduce((acc: FilterOption[], item) => {
    const label = item[labelKey as keyof T] as unknown as string;
    const value = item[valueKey as keyof T] as unknown as string;

    if (label && value) {
      acc.push({ label, value: slugify(value.toString()) });
    }

    return acc;
  }, []);

  if (defaultOption) {
    options.unshift({ label: defaultOption, value: defaultOption });
  }

  if (addAll) {
    options.unshift({ label: 'All', value: '' });
  }

  return options;
};
